<template>
  <div class="uploader">

    <div v-if="docs.length==0">{{placeholder}}</div>
    <video controls="controls" :src="docs[index].url" v-if="docs.length > 0 && isVideo(docs[index].mimetype)">
        Your browser does not support the HTML5 Video element.
    </video>
    <img v-else-if="docs.length > 0 && docs[index].mimetype.startsWith('image')" :src="docs[index].url" style="width: auto;" />
    <div class="container " :class="{'text-white': darkBg === true}">
      <div class="m-2 on-top" v-if="docs.length > 0 && docs[index].url" :class="currentDocIcon">
        <a :href="docs[index].url" target="_blank" class="filename" :class="{'text-white': darkBg === true}">{{currentDocName}}</a>
        <div class="filenumber" :class="{'text-white': darkBg === true}">(Datei {{index + 1}} von {{docs.length}})</div>
        <a v-if="docs.length>0 && !docs[index].mimetype.startsWith('image') && !docs[index].mimetype.startsWith('video')" :href="docs[index].url" target="_blank" class="m-2 text-white center">{{docs[index].mimetype}}</a>
      </div>


      <div v-if="multiple" class="row">
        <div class="col-12 text-center mt-2" v-if="currentUserRights.includes('BHB - Vollbild_Layout hochladen')">
          <base-button v-if="docs.length==0" size="sm" type="primary" @click="$refs.fileinput.click()">
            <input ref="fileinput" :name="name+multiple?'[]':''" type="file" :multiple=false @change="add($event.target.files)">
            <i class="fa fa-upload fa-large m-2" ></i>
          </base-button>
          <base-button size="sm" type="primary" @click="remove(docs[index])">
            <i class="fa fa-trash fa-large m-2" ></i>
          </base-button>
        </div>
      </div>
      <div v-if="!multiple" class="row">
        <div class="col-12 text-center">
          <label>
            <i class="fa fa-plus-circle"></i>
            <input :name="name+multiple?'[]':''" type="file" :multiple="multiple" @change="add($event.target.files)">
          </label>
          <i class="fa fa-plus-circle fa-large"></i>
        </div>
      </div>
    </div>
    <div v-if="docs.length==0" class="dd-container">
      <span><i class="fa fa-plus-circle fa-large"></i></span>
        <el-upload
          class="avatar-uploader"
          drag
          :multiple="false"
          action=""
          :on-change="add"
          :auto-upload="false"
          :show-file-list="false">
        </el-upload>
    </div>
  </div>
</template>
<script>
  // Charts
  import { mapGetters } from "vuex"
  import { dataURItoBlob, resizeImage, isVideo, isImage } from '@/util/data'

  export default {
    name: 'uploader',

    props: ['type', 'id', 'documents', 'name', 'multiple', 'placeholder', 'darkBg'],
    data() {
      return {
        docs: [],
        index: 0,
      }
    },
    computed: {
      ...mapGetters(["currentUserRights"]),
      currentDocName () {
        const doc = this.docs[this.index]
        if (doc) {
          return doc.original
        }
        return ''
      },

      currentDocIcon() {
        const doc = this.docs[this.index];

        if (this.isImage(doc.mimetype) || this.isVideo(doc.mimetype)) {
          return "";
        }

        if (doc['mimetype'] == 'application/pdf') {
          return 'pdf';
        }
        if (doc['mimetype'] == 'application/zip') {
          return 'zip';
        }
        if (doc['mimetype'] == 'application/msword' ||
          doc['mimetype'] == 'application/vnd.ms-word' ||
          doc['mimetype'] == 'application/vnd.openxmlformats-officedocument. wordprocessingml.document') {
          return 'doc';
        }

        if (doc['mimetype'] == 'application/msexcel' ||
          doc['mimetype'] == 'application/vnd.ms-excel' ||
          doc['mimetype'] == 'application/vnd.openxmlformats-officedocument. spreadsheetml.sheet') {
          return 'xls';
        }
        return 'file';
      }

    },
    methods: {
      reset() {
        const self = this
        self.docs = []
        if (this.id) {
          this.$store.dispatch('getDocs', {type: this.type, id: this.id})
            .then(items => {
              if (items && items.position[self.id] && items.position[self.id][self.name]) {
                self.docs = items.position[self.id][self.name]
              } else {
                self.docs = []
              }
            })
        } else if (this.documents) {
          this.docs = this.documents ? this.documents.filter(d => d.path === this.name) : []
        }
      },

      isImage,
      isVideo,
      uploadFile(reader, file) {
        const self = this;

        reader.onload = function (e) {
          file.url = e.target.result
          self.docs.push({
            upload: file,
            mimetype: file.type,
            url: e.target.result,
          });
          self.index = self.docs.length-1
          self.$emit('changed', self.docs.filter(d => d.upload).map(d => d.upload))
        }
        reader.readAsDataURL(file)
      },

      add(files) {
        if (!this.multiple) {
          this.docs = []
        }
        const self = this;

        if (files.constructor == FileList) {
          for (var i=0; i<files.length; i++) {
            var reader = new FileReader();
            const file = files[i]
            let maxFileSize = 1e+7

            if (this.isVideo(file.type)) {
              maxFileSize = 2.5e+7
            }
            
            // const size = Math.round((file.size / 1024));

            if (file.size >= maxFileSize) {
              alert(this.$t('message.file-size'))
              return
            }

            if (self.isImage(file['type'])) {
              resizeImage({ maxSize: 1920, file })
                .then(blob => {
                  const image = new File([blob], file.name, { type: blob.type })
                  self.uploadFile(reader, image);
              })
            } else {
              self.uploadFile(reader, file);
            }
          }
        } else { // is draggable
            var reader = new FileReader();
            const file = files.raw;
            const size = Math.round((file.size / 1024));
            let maxFileSize = 1e+7

            if (this.isVideo(file.type)) {
              maxFileSize = 2.5e+7
            }

            if (size >= maxFileSize) {
              alert(this.$t('message.file-size'))
              return
            }

            if (self.isImage(file['type'])) {
              resizeImage({ maxSize: 1920, file })
                .then(blob => {
                  const image = new File([blob], file.name, { type: blob.type })
                  self.uploadFile(reader, image);
              })
            } else {
              self.uploadFile(reader, file);
            }
        }
        this.$refs.fileinput.value = ''
      },
      async remove (doc) {
         try {
          await this.$confirm(
            `Wollen Sie dieses Design wirklich löschen?`,
            `Design löschen`,
            {
              confirmButtonText: this.$t("message.yes"),
              cancelButtonText: this.$t("message.no"),
              type: "warning"
            }
          )
          this.docs.splice(this.index - 1, 1)
          await this.$store.dispatch('deleteDocApi', {docs: [doc]})
          
          this.$emit('removed', this.docs)
        } catch (error) {
          return;
        }
      }
    },
    watch: {
      id: function(newVal, oldVal) {this.reset()},
      documents() {
          this.docs = this.documents ? this.documents.filter(d => d.path === this.name) : []
      }

    },
    mounted() {
      this.reset()
    }
  }
</script>
<style lang="scss">
  .uploader img {
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 70%;
  }

  .uploader video {
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 70%;
    margin-top:50px;
  }

  .uploader > div {
    text-align: center;
  }
  .uploader i {
    cursor: pointer;
  }
  .uploader input {
    display: none;
  }

  .uploader a.center {
    display: block;
    text-align: center;
  }
  .uploader a:hover {
    text-decoration: underline;
  }
  .uploader .on-top {
    position: relative;
  }

  .avatar-uploader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .uploader .filename {
    padding-top: 70px;
  }

  .uploader .filenumber {
    font-size: 0.75rem;
  }

  .uploader .on-top:before {
    content: " ";
    width: 50px;
    height: 50px;
    position: relative;
    top: -10px;
    margin: auto;
    display: block;
    pointer-events: none;
  }
  .uploader .on-top.pdf:before {
    background: url(../assets/images/pdf.png) 0 0/contain no-repeat transparent;
  }
  .uploader .on-top.xls:before {
    background: url(../assets/images/xls.png) 0 0/contain no-repeat transparent;
  }
  .uploader .on-top.doc:before {
    background: url(../assets/images/doc.png) 0 0/contain no-repeat transparent;
  }
  .uploader .on-top.zip:before {
    background: url(../assets/images/zip.png) 0 0/contain no-repeat transparent;
  }
  .uploader .on-top.file:before {
    background: url(../assets/images/file.png) 0 0/contain no-repeat transparent;
  }

  .avatar-uploader .el-upload {
    width: 100%;
    height: 100%;
  }

  .avatar-uploader .el-upload-dragger {
    width: 100%;
    height: 100%;
    background: transparent;
  }


  .avatar-uploader .el-upload-dragger:hover {
    background-color: rgba(255, 255, 255, .1);
    border: 1px dashed #000;
    -webkit-box-sizing: border-box;
  }

  .dd-container {
    padding-top: 15px;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
  }


</style>
