<template>
  <div class="image-editor-container d-flex flex-column align-self-stretch">
    <!-- <el-upload
          class="avatar-uploader"
          drag
          action=""
          :on-change="fileDragged" 
          :auto-upload="false"
          :show-file-list="false">
        </el-upload> -->
    <div>
      <base-button
        size="sm"
        @click="save"
        :loading="isLoading"
        class="m-3"
        :disabled="project && project.state === 'ARCHIVED'"
        v-if="isSaveButtonVisible"
      >
        {{ $t("message.save") }}
      </base-button>
    </div>

    <div ref="tuiImageEditor" style="width: 100%; height: 100%"></div>
    <div class="dd-container">
      <span><i class="fa fa-plus-circle fa-large"></i></span>
      <el-upload
        class="avatar-uploader"
        drag
        action=""
        :on-change="fileDragged"
        :auto-upload="false"
        :show-file-list="false"
      >
      </el-upload>
    </div>
  </div>
</template>
<script>
import ImageEditor from "tui-image-editor";
import { b64toBlob, resizeImage } from "@/util/data";
import "tui-image-editor/dist/tui-image-editor.min.css";
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");
import { mapGetters } from "vuex";
import { constrainPoint } from "@fullcalendar/core";

var blackTheme = {
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
  "submenu.normalIcon.path": icond,
  "submenu.activeIcon.path": iconb,
  "common.bi.image": "",
  "common.bisize.width": "0px",
  "common.bisize.height": "0px",
  "common.backgroundImage":
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAABemlDQ1BJQ0MgUHJvZmlsZQAAKM9jYGAqSSwoyGFhYGDIzSspCnJ3UoiIjFJgv8PAzcDDIMRgxSCemFxc4BgQ4MOAE3y7xsAIoi/rgsxK8/x506a1fP4WNq+ZclYlOgz4AXdKanEyAwMjB5CdnFKcnAtk5wDZOskFRSVA9gwgW7e8pADEPgFkixQBHQhk3wGx0yHsDyB2EpjNxAJWExLkDGRLANkCSRC2BoidDmFbgNjJGYkpQLYHyC6IG8CA08NFwdzAUteRgcogN6cUZgcotHhS80KDQe4AYhkGDwYXBgUGcwYDBksGXQbHktSKEpBC5/yCyqLM9IwSBUdgyKYqOOfnFpSWpBbpKHjmJevpKBgZGBqA1IHiDGL05yCw6Yxi5xFi+QsZGCyVGRiYexBiSdMYGLbvYWCQOIUQU5nHwMBvzcCw7VxBYlEi3OGM31gI8YvTjI0gbB4nBgbWe///f1ZjYGCfxMDwd+L//78X/f//dzHQfmCcHcgBACR3aeD10IV6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczpleGlmPSJodHRwOi8vbnMuYWRvYmUuY29tL2V4aWYvMS4wLyI+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xMTA8L2V4aWY6UGl4ZWxYRGltZW5zaW9uPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+OTk8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KydpWSQAAACVJREFUKFNj/Pnz538GIgFYMRsbG5SLHzBBaaLAqGJkQCvFDAwAlKIH+ycXcc8AAAAASUVORK5CYII=",
  "common.backgroundColor": "#fff",
  "common.border": "1px solid #c1c1c1",

  // header
  "header.backgroundImage": "none",
  "header.backgroundColor": "transparent",
  "header.border": "0px",

  // load button
  "loadButton.backgroundColor": "#fff",
  "loadButton.border": "1px solid #ddd",
  "loadButton.color": "#222",
  "loadButton.fontFamily": "'Noto Sans', sans-serif",
  "loadButton.fontSize": "12px",

  // download button
  "downloadButton.backgroundColor": "#fdba3b",
  "downloadButton.border": "1px solid #fdba3b",
  "downloadButton.color": "#fff",
  "downloadButton.fontFamily": "'Noto Sans', sans-serif",
  "downloadButton.fontSize": "12px",

  // main icons
  "menu.normalIcon.name": "icon-d",
  "menu.activeIcon.name": "icon-b",
  "menu.disabledIcon.name": "icon-a",
  "menu.hoverIcon.name": "icon-c",
  "menu.iconSize.width": "24px",
  "menu.iconSize.height": "24px",

  // submenu primary color
  "submenu.backgroundColor": "transparent",
  "submenu.partition.color": "#e5e5e5",

  // submenu icons
  "submenu.normalIcon.name": "icon-d",
  "submenu.activeIcon.name": "icon-b",
  "submenu.iconSize.width": "32px",
  "submenu.iconSize.height": "32px",

  // submenu labels
  "submenu.normalLabel.color": "#858585",
  "submenu.normalLabel.fontWeight": "normal",
  "submenu.activeLabel.color": "#000",
  "submenu.activeLabel.fontWeight": "normal",

  // checkbox style
  "checkbox.border": "1px solid #ccc",
  "checkbox.backgroundColor": "#fff",

  // rango style
  "range.pointer.color": "#333",
  "range.bar.color": "#ccc",
  "range.subbar.color": "#606060",

  "range.disabledPointer.color": "#d3d3d3",
  "range.disabledBar.color": "rgba(85,85,85,0.06)",
  "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

  "range.value.color": "#000",
  "range.value.fontWeight": "normal",
  "range.value.fontSize": "11px",
  "range.value.border": "0",
  "range.value.backgroundColor": "#f5f5f5",
  "range.title.color": "#000",
  "range.title.fontWeight": "lighter",

  // colorpicker style
  "colorpicker.button.border": "0px",
  "colorpicker.title.color": "#000",
};
const includeUIOptions = {
  includeUI: {
    initMenu: "draw",
    theme: blackTheme,
    menu: ["draw", "shape", "crop", "text"],
    loadImage: {
      path: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      name: "Blank",
    },
  },
};
const editorDefaultOptions = {
  cssMaxWidth: 700,
  cssMaxHeight: 500,
};
export default {
  name: "ImageEditor",
  props: {
    isSaveButtonVisible: {
      type: Boolean,
      default: true,
    },
    includeUi: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default() {
        return editorDefaultOptions;
      },
    },
    src: {
      type: String,
    },
    value: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["currentUserRights", "project"]),
  },
  mounted() {
    const self = this;
    let options = editorDefaultOptions;
    if (this.includeUi) {
      options = Object.assign(includeUIOptions, this.options);
    }
    this.editorInstance = new ImageEditor(this.$refs.tuiImageEditor, options);
    this.addEventListener();

    this.editorInstance.loadImageFromURL = (function () {

      var cached_function = self.editorInstance.loadImageFromURL;
      function waitUntilImageEditorIsUnlocked(imageEditor) {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (!imageEditor._invoker._isLocked) {
              clearInterval(interval);
              resolve();
            }
          }, 1);
        });
      }

      return function () {
        return waitUntilImageEditorIsUnlocked(self.editorInstance).then(() =>
          cached_function.apply(this, arguments)
        );
      };
    })();
    if(this.value){
      const tempImage = new Image()
      tempImage.crossOrigin = "Anonymous"
      tempImage.src = JSON.parse(this.value).backgroundImage.src
    }
    

    this.editorInstance.on("imageChanged", function (cause) {

      self.checkSize();
      const image = self.editorInstance._graphics._canvas.backgroundImage;
      if (cause === "delete") {
        self.$emit("delete");
      }
    });

    if (this.value) {
      // this.editorInstance.loadImageFromURL(JSON.parse(this.value).backgroundImage.src)
      this.editorInstance.loadFromJSON(this.value);
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.editorInstance.loadFromJSON(val);
      }
    },
  },
  destroyed() {
    Object.keys(this.$listeners).forEach((eventName) => {
      this.editorInstance.off(eventName);
    });
    this.editorInstance.destroy();
    this.editorInstance = null;
  },
  methods: {
    addEventListener() {
      this.editorInstance.on("objectMoved", () => {
        console.log("alaaaarm");
      });
      Object.keys(this.$listeners).forEach((eventName) => {
        this.editorInstance.on(eventName, (...args) =>
          this.$emit(eventName, ...args)
        );
      });
    },
    getRootElement() {
      return this.$refs.tuiImageEditor;
    },
    invoke(methodName, ...args) {
      let result = null;
      if (this.editorInstance[methodName]) {
        result = this.editorInstance[methodName](...args);
      } else if (methodName.indexOf(".") > -1) {
        const func = this.getMethod(this.editorInstance, methodName);
        if (typeof func === "function") {
          result = func(...args);
        }
      }
      return result;
    },
    getMethod(instance, methodName) {
      const { first, rest } = this.parseDotMethodName(methodName);
      const isInstance = instance.constructor.name !== "Object";
      const type = typeof instance[first];
      let obj;
      if (isInstance && type === "function") {
        obj = instance[first].bind(instance);
      } else {
        obj = instance[first];
      }
      if (rest.length > 0) {
        return this.getMethod(obj, rest);
      }
      return obj;
    },
    parseDotMethodName(methodName) {
      const firstDotIdx = methodName.indexOf(".");
      let firstMethodName = methodName;
      let restMethodName = "";
      if (firstDotIdx > -1) {
        firstMethodName = methodName.substring(0, firstDotIdx);
        restMethodName = methodName.substring(
          firstDotIdx + 1,
          methodName.length
        );
      }
      return {
        first: firstMethodName,
        rest: restMethodName,
      };
    },
    isImage(filetype) {
      const imageMimeTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/vnd.microsoft.icon",
        "image/bmp",
        "image/svg+xml",
        "image/webp",
        "image/tiff",
      ];
      return imageMimeTypes.includes(filetype);
    },
    fileDragged(files) {
      const self = this;

      // is draggable
      var reader = new FileReader();
      const file = files.raw;
      const size = Math.round(file.size / 1024);

      if (size >= 8192) {
        alert(this.$t("message.file-size"));
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (!file || !self.editorInstance) {
          return;
        }
        self.editorInstance
          .loadImageFromURL(reader.result, file.name)
          .then((result) => {
            self.editorInstance.ui.resizeEditor({
              imageSize: {
                oldWidth: result.oldWidth,
                oldHeight: result.oldHeight,
                newWidth: result.newWidth,
                newHeight: result.newHeight,
              },
            });
          })
          .catch((err) => {
            console.error("Something went wrong:", err);
          });
      };
    },
    checkSize() {
      const instance = this.editorInstance.toObject();
      const editorBackgroundImage =
        this.editorInstance._graphics._canvas.backgroundImage;
      if (editorBackgroundImage) {
        const backgroundImage = instance.backgroundImage.src;

        // nicht bei existierendem Bild
        if (
          backgroundImage.indexOf("http") !== 0 &&
          backgroundImage.indexOf("data:") !== 0
        ) {
          const r = fetch(backgroundImage)
            .then((r) => r.blob())
            .then((blob) => {
              var file = new File([blob], "annotation.png", {
                type: "image/png",
              });
              
                resizeImage({ maxSize: 1920, file }).then((blob) => {
                  const image = new File([blob], "annotation.png", {
                    type: "image/png",
                  });
                  var reader = new FileReader();

                  reader.readAsDataURL(blob);

                  reader.onload = () => {
                    if (!file || !this.editorInstance) {
                      return;
                    }

                    this.editorInstance
                      .loadImageFromURL(reader.result, file.name)
                      .then((result) => {
                        this.editorInstance.ui.resizeEditor({
                          imageSize: {
                            oldWidth: result.oldWidth,
                            oldHeight: result.oldHeight,
                            newWidth: result.newWidth,
                            newHeight: result.newHeight,
                          },
                        });
                        this.editorInstance._graphics._canvas.renderAll();
                      })
                      .catch((err) => {
                        console.error("Something went wrong:", err);
                      });
                  };
                });
              
            });
        }
      }
    },
    save() {
      // bg Image
      const self = this;
      if (this.editorInstance._graphics._canvas.backgroundImage) {
        const canvas =
          this.editorInstance._graphics._canvas.backgroundImage.getSrc();
        const result = this.editorInstance.toObject();
        const backgroundImage = result.backgroundImage.src;

        // Neues Bild
        if (backgroundImage.indexOf("blob:") === 0) {
          const r = fetch(canvas)
            .then((r) => r.blob())
            .then((blob) => {
              var file = new File([blob], "annotation.png", {
                type: "image/png",
              });
              resizeImage({ maxSize: 1920, file }).then((blob) => {
                const image = new File([blob], "annotation.png", {
                  type: "image/png",
                });
                self.$emit("input", { json: result, image, type: "new" });
              });
            });
        }

        // Unbekannt?
        // eg: data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7
        if (backgroundImage.indexOf("data:") === 0) {
          const data = backgroundImage.split(",")[1];
          result.backgroundImage.src = b64toBlob(data);
          const image = new File([b64toBlob(data)], "annotation.png");
          self.$emit("input", { json: result, image, type: "new" });
        }

        // existierendes Bild
        if (backgroundImage.indexOf("http") === 0) {
          self.$emit("input", { json: result, type: "existing" });
        }
      }
      //current edited image
      // const renderedImage = this.editorInstance.toDataURL()
    },
    getDataUrl() {
      return this.editorInstance.toDataURL();
    },
    clear() {
      this.editorInstance._graphics._canvas.clear();
      this.editorInstance._graphics._canvas.backgroundImage = false;
      this.editorInstance.clearObjects();
      this.editorInstance.clearUndoStack();
      this.editorInstance.clearRedoStack();
      this.editorInstance.loadImageFromURL(
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      );
      this.editorInstance._graphics._canvas.renderAll();
    },
    restore() {
      const json = prompt("json");
      this.editorInstance.loadFromJSON(json);
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
};
</script>
<style lang="scss">
.image-editor-container {
  height: 100%;
  text-align: right;
  padding-bottom: 60px;
}

.image-editor-container
  #tie-draw-line-select-button
  > .tui-image-editor-button {
  padding: 4px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.image-editor-container
  #tie-draw-line-select-button.free
  > .tui-image-editor-button.free,
.image-editor-container
  #tie-draw-line-select-button.line
  > .tui-image-editor-button.line,
.image-editor-container
  #tie-draw-line-select-button.polygon:not(.line):not(.free)
  > .tui-image-editor-button.polygon {
  background-color: #9f2e37;
}

.image-editor-container
  #tie-draw-line-select-button.free
  > .tui-image-editor-button.free
  > label,
.image-editor-container
  #tie-draw-line-select-button.line
  > .tui-image-editor-button.line
  > label,
.image-editor-container
  #tie-draw-line-select-button.polygon:not(.line):not(.free)
  > .tui-image-editor-button.polygon
  > label {
  color: #fff;
}
.image-editor-container
  #tie-draw-line-select-button
  > .tui-image-editor-button
  .svg_ic-submenu {
  mix-blend-mode: screen;
}

.avatar-uploader .el-upload-dragger {
  z-index: 99;
}

.dd-container {
  padding-top: 15px;
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center !important;
}

@media (max-width: 1024px) {
  .dd-container {
    display: none !important;
  }
  .image-editor-container {
    padding-bottom: 0;
  }
}
/* .tui-image-editor-container .tui-image-editor,
.tui-image-editor-controls, 
.tui-image-editor-submenu,
.tui-image-editor-header,
.image-editor-container .btn.m-3.btn-default.btn-sm {
    z-index: 100 !important;
} */
</style>
